<template>
  <b-modal
    id="delete-medical-report-modal"
    centered
    hide-header
    hide-footer
    @hidden="confirmDelete(false)"
  >
    <div class="header">
      <div class="header-content">
        <p>Excluir modelo de laudo</p>
        <v-close class="close-svg" @click="confirmDelete(false)" />
      </div>
    </div>
    <div class="body">
      Você tem certeza que deseja excluir o modelo de laudo
      <span class="description">
        {{ name }}
      </span>
      ? Não será possível reverter essa ação.
    </div>

    <div class="actions">
      <b-button
        class="cancel-button"
        variant="outline-danger"
        @click="confirmDelete(false)"
      >
        Cancelar
      </b-button>
      <b-button variant="primary" @click="confirmDelete(true)">
        Confirmar
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import Close from '@/assets/icons/close.svg'
export default {
  components: {
    'v-close': Close
  },
  props: {
    name: String,
    confirmDelete: Function
  }
}
</script>
<style lang="scss">
#delete-medical-report-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 450px;

    .modal-body {
      padding: 0;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--type-active);

      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 24px;

          .close-svg {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
          }
        }
      }
      .body {
        padding: 24px;
        color: var(--type-active);
        font-size: 18px;
        font-weight: 600;

        .description {
          color: var(--blue-500);
        }
      }
      .actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        padding: 24px;

        button {
          margin-left: 10px !important;
        }

        .cancel-button {
          font-weight: bold !important;
          border-color: transparent !important;
        }
      }
    }
  }
}
</style>
